<template>
  <CModal id="SendSMSMessage" class="ActionModel" :show.sync="Toggle" :centered="true" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <template>
      <CAlert :show="CurrentMemberList.length > 1" color="info">
        <CIcon name="cil-bell" /> 批量發送時若有會員未填寫手機號碼，系統將會忽略該會員的簡訊發送。
      </CAlert>
      <CInput :label="'收件人'" :value="Receiver" horizontal disabled />
      <CInput :label="'主旨'" v-model="Subject" horizontal />
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          訊息內容
        </CCol>
        <CCol sm="9">
          <CTextarea v-model="Message" />
        </CCol>
      </CRow>
    </template>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Organization.SendSMSMessage') }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="Send()" color="success" :disabled="CurrentMemberList.length <= 0">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "SendSMSMessage",
  props: ['Toggle', 'MemberList', 'ChooseUsers'],
  data() {
    return {
      Submit: false,
      Loading: false,
      Subject: '',
      Message: '',
      CurrentMemberList: []
    }
  },
  computed: {
    Receiver () {
      if (this.CurrentMemberList.length === this.MemberList.length) {
        return '發送給目前篩選條件下所有會員'
      } else if (this.CurrentMemberList.length === 1) {
        return `${this.CurrentMemberList[0].Name}(${this.CurrentMemberList[0].Mobile || '--'})`
      } else {
        return '發送給所選' + this.CurrentMemberList.length + '位會員'
      }
    }
  },
  mounted() {
    if (this.ChooseUsers.length > 0) {
      this.CurrentMemberList = this.MemberList.filter(item => this.ChooseUsers.includes(item.Uid))
    } else {
      this.CurrentMemberList = this.MemberList
    }
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
      this.$emit('update:ChooseUsers', [])
    },
    Send() {
      this.Loading = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/notify/sms/send',
        method: 'post',
        data: {
          Message: this.Message,
          Subject: this.Subject || '',
          Phone: this.CurrentMemberList.filter(item => {
            return item.Mobile !== ''
          }).map(item => item.Mobile)
        }
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Message/BulkSendEmailSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Message/BulkSendEmailFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>
